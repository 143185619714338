import { useQuery } from '@tanstack/react-query';

import { apiClient } from 'api/ApiClient';
import { useGetProfile } from 'entities/user/api/useGetProfile';
import type { PromoConfig } from 'hooks/promo';

export type FeaturesResponse = {
  promo?: PromoConfig[];
  flags?: {
    /**
     * shows sandbox switch for individual companies
     */
    sandbox_switch_individual_company?: boolean;

    /**
     * collects fullstories with custom rate when it comes to individual companies
     */
    paygo_fullstory_sample_rate?: number;

    /**
     * shows old users (users route) -- will be dropped as soon as Creative Services finish migration (Apr 2025)
     */
    old_users_companies?: string[];

    /**
     * shows coverage request button & modal
     */
    coverage_request?: boolean;
  };
};

export const useFeatures = (enabled = true) => {
  return useQuery({
    queryKey: ['getFeatures'],
    queryFn: () => apiClient.getAnalyticsSource<FeaturesResponse>('features', {}),
    retry: false,
    enabled,
  });
};

export const useHasAllUsersFeature = () => {
  const { data: features } = useFeatures();
  const { data: profile } = useGetProfile();

  return !new Set(features?.flags?.old_users_companies).has(profile?.company?.id ?? '');
};
