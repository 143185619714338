import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { Button, Tooltip } from '@mui/material';
import {
  BridgeIcon,
  BrushIcon,
  House as HouseIcon,
  InformationIcon,
  LogoIcon,
  NavButton,
  QuestionIcon,
  SettingsBoldIcon,
  ToolsIcon,
  UsersBoldIcon,
} from '@truvhq/ui';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

import { SandboxSwitch } from 'components/SandboxSwitch/SandboxSwitch';
import { useGetMembers } from 'entities/member/api/useGetMembers';
import { isNotApproved } from 'entities/member/lib/tools';
import { useGetProfile } from 'entities/user/api/useGetProfile';
import { useGetKeys } from 'entities/userKey/api/useGetKeys';
import { useHasAllUsersFeature } from 'hooks/features';
import { useImpersonation } from 'hooks/impersonation';
import { useNavigateToBilling } from 'hooks/useNavigateToBilling';
import { useAuth } from 'shared/lib/auth/useAuth';
import { useFiltersContext } from 'shared/lib/filters/useFiltersContext';
import { useSandboxContext } from 'shared/lib/sandbox/useSandboxContext';
import { urls } from 'shared/urls';

import { InfoExternal } from './InfoExternal';
import { ACCORDION_TRANSITION_DURATION, NavItems } from './NavItems';
import { StyledBadge } from './StyledBadge';
import type { Item, State } from './types';
import { useSubmenuState } from './useSubmenuState';
import { isRoutePart } from './utils/isRoutePart';

// extends accordions by default
const initialState = {
  [urls.home.url()]: true,
  [urls.settings.url()]: false,
  [urls.activity.url()]: true,
  [urls.customization.url()]: false,
  [urls.development.url()]: false,
  [urls.supportTickets.url()]: false,
  currentItem: undefined,
};

const iconProps = {
  width: 16,
  height: 16,
};

export const NavBar: FC = () => {
  const location = useLocation();
  const { logout, permissions, user } = useAuth();
  const { data: profileSettings } = useGetProfile();
  const history = useHistory();
  const { resetFilters } = useFiltersContext();
  const { keys } = useGetKeys();
  const { stopImpersonation } = useImpersonation();
  const handleBillingClick = useNavigateToBilling();
  const { submenuState, setSubmenuState } = useSubmenuState(initialState as State);
  const sandboxWrapperRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { isSandboxOn, isSandboxVisible } = useSandboxContext();
  const hasAllUsersPage = useHasAllUsersFeature();

  useEffect(() => {
    const expandedAccordion = Object.keys(submenuState).find(
      (key) =>
        submenuState[key] && (submenuState.currentItem === undefined || isRoutePart(submenuState.currentItem, key)),
    );

    let timeout: NodeJS.Timeout | null = null;

    if (expandedAccordion) {
      const targetAccordion = document.getElementById(`${expandedAccordion}-header`)?.parentElement;

      const scrollToTarget = () => {
        if (
          targetAccordion &&
          targetAccordion.getBoundingClientRect().bottom >
            Number(sandboxWrapperRef.current?.getBoundingClientRect().top)
        ) {
          targetAccordion.scrollIntoView();
        }
      };

      // wrap into timeout on accordion expand to wait for the accordion content width
      if (expandedAccordion === submenuState.currentItem) {
        timeout = setTimeout(scrollToTarget, ACCORDION_TRANSITION_DURATION);
      } else {
        scrollToTarget();
      }
    } else {
      const collapsedAccordion = Object.keys(submenuState).find(
        (key) => !submenuState[key] && submenuState.currentItem === key,
      );
      const targetAccordion = document.getElementById(`${collapsedAccordion}-header`);

      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollBy(
          0,
          -(
            Number(targetAccordion?.parentElement?.getBoundingClientRect().height) -
            Number(targetAccordion?.getBoundingClientRect().height)
          ),
        );
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [submenuState]);

  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      resetFilters();
      stopImpersonation();
      await logout();
      history.push('/');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, [history, logout, resetFilters, stopImpersonation]);

  const handleItemClick = (currentItem?: string) => {
    setSubmenuState({ ...submenuState, currentItem } as State);
  };

  const enableMembersRequest = permissions.has('can_invite_users') && permissions.has('can_view_company_settings');

  const { data: members } = useGetMembers(enableMembersRequest);

  const notApprovedMembers = useMemo(() => members?.filter(isNotApproved).length, [members]);

  const role = profileSettings?.role.id;
  const isDeveloper = role === 'developer';
  const isOrdersManager = role === 'orders_manager';
  const isStaff = !!profileSettings?.staff_dashboard_url;
  const isIndividualVerifier = user?.isCompanyIndividual;
  const newKeys = isDeveloper && keys?.hasNewProdOrDevKeys ? 1 : 0;

  // Initial opening of submenu based on current route
  useEffect(() => {
    const key = Object.keys(submenuState).find((href) => isRoutePart(location.pathname, href));
    const isFromNavigate = submenuState.currentItem !== undefined;

    if (key) {
      setSubmenuState({
        ...submenuState,
        [key]: true,
        currentItem: isFromNavigate ? location.pathname : key,
      } as State);
    }
  }, []);

  const menuItems: Item[] = useMemo(
    () => [
      {
        title: urls.home.title,
        href: urls.home.url(),
        permission: urls.home.permission,
        hide: isOrdersManager && isSandboxOn,
        icon: <HouseIcon {...iconProps} />,
        submenu: isSandboxOn
          ? undefined
          : [
              {
                title: urls.reporting.title,
                href: urls.reporting.url(),
                permission: urls.reporting.permission,
              },
              {
                title: urls.usage.title,
                href: urls.usage.url(),
                permission: urls.usage.permission,
                hide: isIndividualVerifier,
              },
              {
                title: urls.performance.title,
                href: urls.performance.url(),
                permission: urls.performance.permission,
              },
              {
                title: urls.managers.title,
                href: urls.managers.url(),
                permission: urls.managers.permission,
                hide: isIndividualVerifier,
              },
            ],
      },
      {
        title: urls.emulator.title,
        href: urls.emulator.url(),
        permission: urls.emulator.permission,
        icon: <BridgeIcon {...iconProps} />,
      },
      {
        title: urls.activity.title,
        href: urls.activity.url(),
        icon: <UsersBoldIcon {...iconProps} />,
        submenu: [
          {
            title: urls.allUsers.title,
            href: urls.allUsers.url(),
            permission: urls.allUsers.permission,
            hide: !hasAllUsersPage,
          },
          {
            title: urls.users.title,
            href: urls.users.url(),
            permission: urls.users.permission,
            hide: hasAllUsersPage,
          },
          {
            title: urls.orders.title,
            href: urls.orders.url(),
            permission: urls.orders.permission,
          },
          {
            title: urls.tasks.title,
            href: urls.tasks.url(),
            permission: urls.tasks.permission,
          },
          {
            title: urls.coverage.title,
            href: urls.coverage.url(),
            permission: urls.coverage.permission,
          },
          // TODO: show to individual clients
          // {
          //   title: 'Pay as you go',
          //   href: '#',
          //   hide: !isIndividualVerifier,
          //   info: (
          //     <StyledBadge
          //       badgeContent="UPGRADE"
          //       slotProps={{
          //         badge: {
          //           className: 'pointer-events-none bg-primary-main text-white text-[10px]',
          //         },
          //       }}
          //     />
          //   ),
          // },
        ],
      },
      {
        title: urls.customization.title,
        href: urls.customization.url(),
        icon: <BrushIcon {...iconProps} />,
        permission: urls.customization.permission,
        submenu: [
          {
            title: urls.branding.title,
            href: urls.branding.url(),
            permission: urls.branding.permission,
          },
          {
            title: permissions.has('can_view_orders') ? 'Order settings' : 'Bridge settings',
            href: urls.customSettings.url(),
            permission: urls.customSettings.permission,
          },
          {
            title: urls.templates.title,
            href: urls.templates.url(),
            permission: urls.templates.permission,
          },
          {
            title: urls.fieldsCustomization.title,
            href: urls.fieldsCustomization.url(),
            permission: urls.fieldsCustomization.permission,
            hide: isIndividualVerifier,
          },
          {
            title: urls.marketplace.title,
            href: urls.marketplace.url(),
            permission: urls.marketplace.permission,
            hide: !isStaff,
          },
        ],
      },
      {
        title: urls.development.title,
        href: urls.development.url(),
        icon: <ToolsIcon {...iconProps} />,
        permission: urls.development.permission,
        submenu: [
          {
            title: urls.keys.title,
            href: urls.keys.url(),
            permission: urls.keys.permission,
            info: newKeys ? <StyledBadge badgeContent={newKeys} color="secondary" /> : undefined,
          },
          {
            title: urls.platforms.title,
            href: urls.platforms.url(),
            permission: urls.platforms.permission,
          },
          {
            title: urls.logs.title,
            href: urls.logs.url(),
            permission: urls.logs.permission,
          },
          {
            title: urls.webhooks.title,
            href: urls.webhooks.url(),
            permission: urls.webhooks.permission,
          },
          // {
          //   title: urls.systemStatus.title,
          //   href: urls.systemStatus.url(),
          //   permission: urls.systemStatus.permission,
          // },
        ],
      },
      {
        title: 'Help',
        href: urls.supportTickets.url(),
        icon: <InformationIcon {...iconProps} />,
        permission: urls.supportTickets.permission,
        submenu: [
          {
            title: urls.supportTickets.title,
            href: undefined,
            customAction() {
              window.open(urls.supportTickets.url(), '_blank');
            },
            permission: urls.supportTickets.permission,
            info: (
              <div className="flex items-center gap-1">
                <Tooltip
                  title={
                    <span>
                      We are upgrading our support portal, previous issues are still viewable{' '}
                      <Button
                        className="-mt-[2px] min-w-[0px] p-0 text-xs font-medium text-white underline hover:text-white"
                        onClick={() => history.push(urls.supportTickets.url())}
                      >
                        here
                      </Button>
                    </span>
                  }
                >
                  <div className="absolute right-18 h-5 cursor-pointer">
                    <QuestionIcon className="mt-[3px] text-white" height={16} width={16} />
                  </div>
                </Tooltip>
                <InfoExternal className="text-white" />
              </div>
            ),
          },
          {
            title: urls.docs.title,
            customAction() {
              window.open(urls.docs.parameterUrl?.(isOrdersManager), '_blank');
            },
            permission: urls.docs.permission,
            info: <InfoExternal className="text-white" />,
            hide: isIndividualVerifier,
          },
        ],
      },
      {
        title: urls.settings.title,
        href: urls.settings.url(),
        icon: <SettingsBoldIcon {...iconProps} />,
        submenu: [
          {
            title: urls.company.title,
            href: urls.company.url(),
            permission: urls.company.permission,
          },
          {
            title: urls.team.title,
            href: urls.team.parameterUrl?.() ?? '/',
            info: notApprovedMembers ? <StyledBadge badgeContent={notApprovedMembers} color="secondary" /> : undefined,
            permission: urls.team.permission,
          },
          {
            title: urls.billing.title,
            permission: urls.billing.permission,
            customAction: handleBillingClick,
            info: <InfoExternal className="text-white" />,
          },
          {
            title: 'Profile',
            href: urls.profile.url(),
          },
          {
            title: 'Log out',
            customAction: handleLogout,
          },
        ],
      },
      // TODO: show to individual clients
      // {
      //   title: 'Refer a friend',
      //   href: '#',
      //   hide: !isIndividualVerifier,
      // },
    ],
    [
      handleBillingClick,
      handleLogout,
      isIndividualVerifier,
      isOrdersManager,
      isSandboxOn,
      isStaff,
      newKeys,
      notApprovedMembers,
      permissions,
      hasAllUsersPage,
      history,
    ],
  );

  return (
    <>
      <PerfectScrollbar
        className="flex max-h-screen w-216 flex-grow flex-col bg-grey90"
        // @ts-expect-error -- perfectScrollbar uses legacy ref
        containerRef={(ref) => ref && (scrollContainerRef.current = ref)}
      >
        <div className="flex justify-start py-8 pl-6">
          <RouterLink to="/">
            <LogoIcon className="h-5 w-auto text-white" />
          </RouterLink>
        </div>
        <div className="relative flex flex-grow flex-col bg-grey90 pt-2">
          <NavItems
            items={menuItems}
            pathname={location.pathname}
            permissions={permissions}
            setSubmenuState={setSubmenuState}
            submenuState={submenuState}
            onItemClick={handleItemClick}
          />
        </div>
        {isSandboxVisible && (
          <div ref={sandboxWrapperRef} className="sticky bottom-0 flex flex-col bg-white/10 py-1.5 backdrop-blur-lg">
            {isStaff && (
              <NavButton
                className="relative -left-2 mx-4"
                title="Staff dashboard"
                onClick={() => {
                  window.open(profileSettings.staff_dashboard_url, '_blank');
                }}
              />
            )}

            <div className="pl-4">
              <SandboxSwitch invert />
            </div>
          </div>
        )}
      </PerfectScrollbar>
    </>
  );
};
