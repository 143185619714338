import type { DataSourceFlow } from 'components/Emulator/utils/types';
import type { CategoryId, SubcategoryId } from 'components/SupportTickets/CreateSupportTicket/types';

export type ErrorItem = {
  field?: string;
  message?: string;
};

export type BackendErrorResponse = {
  error?: {
    code?: string;
    extra?: {
      'invalid-params'?: ErrorItem[];
    };
    message?: string;
  };
};

export type SuggestionSuccessRate = 'high' | 'low' | 'unsupported';

export type TruvSuggestionType = {
  name: string;
  domain: string;
  logo_url: string;
  company_mapping_id: string;
  confidence_level: string;
  sso?: {
    name: string;
  };
  success_rate: SuggestionSuccessRate;
};

export type BillingLinkResponse = {
  url: string;
};

export type BrandingResponse = {
  client_name: null | string;
  color_primary: null | string;
  color_background: null | string;
  logo: null | string;
  client_customer_support_email: null | string;
  client_user_agreement_link: null | string;
  client_user_agreement_link_title: null | string;
  is_white_background_enabled: boolean;
  is_confetti_hidden: boolean;
  is_get_help_hidden: boolean;
  is_full_view_enabled: boolean;
  is_before_you_go_screen_hidden: boolean;
  is_citadel_logo_hidden: boolean;
  how_it_works: null | 'link' | 'full_screen';
  consent_screen: null | 'link' | 'full_screen';
  text_labels: {
    income_employer_search_screen_header: null | string;
  };
};

export type ProfileSuggestResponse = {
  first_name: string;
  last_name: string;
  client_name: string;
};

export type CurrentCompanyResponse = {
  billing_email: string;
  client_id: string;
  company_type: CompanyType;
  has_prod_usage: boolean;
  name: string;
  parent_company_name: string;
};

export type CompanyType = 'client' | 'employer' | 'individual';

export type CompanyResponse = CurrentCompanyResponse & {
  address: string;
  alerts_email: string;
  city: string;
  legal_name: string;
  show_prod_keys_to_developers: boolean;
  state: string;
  website: string;
  zip_code: string;
};

export type CompanyPermissionGroup = {
  name: 'company_individual' | 'company_with_embedded' | 'company_with_embedded_and_orders' | 'company_with_orders';
};

export type KeyDescription = {
  access_key: string;
  env_type: EnvType;
  created_at: string;
  client_id: string;
  webhook_url: string | null;
  last_used_at: string | null;
  user_key_id: string;
};

type KeyGroup = {
  active_keys: KeyDescription[];
  can_create_new_key: boolean;
  title: string;
};

export type UserKeysResponse = Record<EnvType, KeyGroup>;

export type Ordering = 'created_at' | '-created_at' | 'status' | '-status' | 'provider' | '-provider' | '-env' | 'env';

export const allEnvTypes = ['sandbox', 'dev', 'prod'] as const;

export type EnvType = (typeof allEnvTypes)[number];

export type BillingLinkParams = {
  return_url: string;
};

export const PRODUCTS_WITH_EXTERNAL_REPORT = [
  'income',
  'employment',
  'insurance',
  'pll',
  'deposit_switch',
] as VerificationType[];
export type ProductWithExternalReport = (typeof PRODUCTS_WITH_EXTERNAL_REPORT)[number];

export type ReportParams = {
  product_type: ProductWithExternalReport;
  access_token: string;
};

export type VerificationReport = {
  access_token: string;
  completed_at: string;
  employments?: Employment[];
  id: string;
  pdf_report?: string;
  provider?: SubOrderProvider;
  status: string;
  tracking_info: string | null;
  deposit_details?: BankAccount;
  insurance?: Insurance;
  identity?: Identity;
  data_source: 'payroll' | 'tax' | 'docs';
};

export type PagingFilters = {
  page_size: number;
  page: number;
};

export type BaseFilters = {
  created_at__lte?: string;
  created_at__gte?: string;
  env?: EnvType[];
};

export type UserFilters = BaseFilters &
  PagingFilters & {
    provider?: string;
    query?: string;
    product_type?: VerificationType;
  };

export type TasksFilters = Partial<PagingFilters> & {
  query?: string;
  created_at__lte?: string;
  created_at__gte?: string;
  updated_at__lte?: string;
  updated_at__gte?: string;
  status?: TaskStatus;
  error_message__icontains?: string;
  link__tracking_info?: string;
  link__tracking_info__icontains?: string;
  ordering?: string;
  env?: EnvType[];
  provider?: string;
  product_type?: string;
};

export const TaskStatusMap = {
  NEW: 'new',
  LOGIN: 'login',
  MFA: 'mfa',
  PARSE: 'parse',
  SWITCH_DEPOSIT: 'switch_deposit',
  CONVERT: 'convert',
  DONE: 'done',

  NO_DATA: 'no_data',
  UNAVAILABLE: 'unavailable',
  ERROR: 'error',
  LOGIN_ERROR: 'login_error',
  ACCOUNT_LOCKED: 'account_locked',
  MFA_ERROR: 'mfa_error',
  CONFIG_ERROR: 'config_error',
  NOT_SUPPORTED: 'not_supported',
  UNABLE_TO_RESET: 'unable_to_reset',
} as const;

export type TaskStatus = keyof typeof TaskStatusMap;

export type TaskItemProvider = SubOrderProvider;

export type TaskItem = {
  bridge_token: string | null;
  product_type: VerificationType;
  data_source: DataSource;
  id: string;
  link_id: string;
  status: ValueOf<typeof TaskStatusMap>;
  environment: EnvType;
  provider: TaskItemProvider | null;
  created_at: string;
  updated_at: string;
  duration: number;
  error_message?: string;
  access_token_id: string;
  tracking_info?: string;
  employer?: string;
  is_data_removed: boolean;
  applicant: Applicant | null;
  is_suspicious: boolean;
};

type BaseListResult = {
  count: number;
  next: string | null;
  previous: string | null;
};

export type ListResult<T> = BaseListResult & {
  results: T[];
};

export type UserItem = {
  applicant?: Applicant;
  applicant_report_id: string | null;
  created_at: string;
  employers: {
    name: string;
  }[];
  first_name: string;
  id: string;
  last_name: string;
  tasks: TaskItem[];
  voa_report_id: string | null;
};

export type LogItem = {
  bridge_token: string | null;
  data: string;
  environment: EnvType;
  headers: string;
  host: string;
  is_webhook: boolean;
  method: string;
  path: string;
  payroll_provider: string | null;
  provider: string | null;
  requested_at: string;
  response: string;
  response_headers: string;
  status_code: number;
  link_id: string | null;
  task_id: string | null;
};

export type LogsFilters = Partial<PagingFilters> & {
  requested_at__lte?: string;
  requested_at__gte?: string;

  status_code?: string;
  method?: string;
  path?: string;
  payroll_provider?: string;
  env?: EnvType[];
  ordering?: string;
  search?: string;
};

export type OptionsResult = {
  task_statuses: TaskStatusesMap;
};

export type TaskStatusesMap = {
  [key: string]: string;
};

export type AdditionalProductTypes = 'insurance' | 'transactions';

export type AssetsProductType = 'assets';
export type VerificationType =
  | 'employment'
  | 'income'
  | 'deposit_switch'
  | 'pll'
  | 'admin'
  | AdditionalProductTypes
  | AssetsProductType;

export type ExtendedProductType = VerificationType | 'scoring_attrs';

export type ProductType = ExtendedProductType | AssetsProductType;

export enum RemindType {
  OneDay = 'remind_24',
  TwoDays = 'remind_48',
  ThreeDays = 'remind_72',
  OneWeek = 'remind_120',
  TwoWeeks = 'remind_240',
  ThreeWeeks = 'remind_360',
  FourWeeks = 'remind_480',
}

export enum RemindTypeNew {
  OneDay = '24',
  TwoDays = '48',
  ThreeDays = '72',
  OneWeek = '120',
  TwoWeeks = '240',
  ThreeWeeks = '360',
  FourWeeks = '480',
}

export const PRODUCTS_WITH_ACCOUNT = ['deposit_switch', 'pll'];

export type FieldGroups = FieldGroup[];

export type FieldGroup = {
  id: string;
  name: string;
  categories: FieldCategory[];
};

export type FieldCategory = {
  id: string;
  name: string;
  mandatory: boolean;
  fields: Field[];
  customizable: boolean;
};

export type Field = {
  id: string;
  name: string;
  description: string;
  mandatory: boolean;
  included: boolean;
};

export type ExcludedFields = {
  [key: string]: {
    [key: string]: string[] | null;
  };
};

export type WidgetSettings = {
  cf_schema: FieldGroups;
  /**
   * Only for PATCH requests, no actual data there
   */
  /* writeonly */ cf_exclude_fields: ExcludedFields;
  client_customer_support_email: string;
  client_name?: string;
  product_type?: VerificationType;
  is_order_invoices_enabled: boolean;
  is_custom_field_enabled: boolean;
  is_tax_product_enabled: boolean;
  custom_field_title?: string;
  is_doc_upload_enabled?: boolean;

  color_primary: string;
  logo?: string;
  client_user_agreement_link?: string;
  client_user_agreement_link_title?: string;
  is_white_background_enabled?: boolean;
  is_get_help_hidden?: boolean;
  is_full_view_enabled?: boolean;
  remind_type: RemindType;

  has_external_search?: boolean;
  custom_domain: string | null;

  orders_product_types: VerificationType[];
  allowed_product_types: ProductType[];
  use_case?: UseCase;
  waterfall_data_sources?: Record<
    VerificationType,
    {
      data_sources: DataSource[];
      flow: DataSourceFlow;
    }
  >;
};

export type WebhookSetting = {
  env: EnvType;
  url?: string;
};

export const ALL_SUBORDER_STATUSES = [
  'pending',
  'sent',
  'completed',
  'error',
  'canceled',
  'expired',
  'no_data',
  'skipped',
] as const;

type SuborderStatusTuple = typeof ALL_SUBORDER_STATUSES;

export type SubOrderStatus = SuborderStatusTuple[number];

export const ALL_ORDER_FILTER_STATUSES = [
  '', // any
  'completed',
  'waiting',
  'expired',
  'canceled',
  'error',
  'skipped',
  'no_data',
] as const;

type OrderFilterStatusTuple = typeof ALL_ORDER_FILTER_STATUSES;

export type OrderFilterStatus = OrderFilterStatusTuple[number];

export type Applicant = {
  external_user_id: number | null;
  first_name: string | null;
  id: string;
  last_name: string | null;
  ssn: string | null;
};

export type SubOrderProvider = {
  id: string;
  name: string;
  logo_url?: string;
};

// in Emulator demo results we receive only string, not SubOrderProvider object
export type DemoSubOrder = Omit<SubOrder, 'provider'> & {
  provider: string;
};

/**
 * @todo fix company_name: string | null. Will break in a lot of places
 */
export type SubOrder = {
  id: string;
  access_token: string;
  status: SubOrderStatus;
  order_number: string;
  product_type: VerificationType;
  suborder_number: string;
  start_date: string;
  end_date: string;
  company_name: string;
  created_at: string;
  company_address: CompanyAddress | null;
  company_domain: string;
  company_google_place_id: string;
  company_logo?: string;
  pdf_report?: string;
  employments?: Employment[] | null;
  insurance?: SubOrder;
  completed_at?: string;
  is_data_removed: boolean;
  is_suspicious?: boolean;
  last_payment: Payment | null;
  last_task_id?: string;
  link_id?: string;
  deposit_details?: BankAccount;
  provider?: SubOrderProvider;
  task_refresh_count?: number;
  data_source: DataSource;
};

export type AssetsLink = {
  link_id: string;
  tracking_info?: string;
  provider: string;
  provider_info?: TaskItemProvider;
  identities: Identity[];
  accounts: Account[];
  transactions: Transaction[];
  owners: {
    id: string;
    full_name: string;
    email: string;
    phone: string;
    address: Address;
  }[];
};

export type Borrower = {
  id: string;
  external_user_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  ssn: string;
  created_at: string;
  updated_at: string;
};

type AssetsSummary = {
  avg_30: string | null;
  avg_60: string | null;
  avg_90: string | null;
  currency_code: string;
  balance: string | null;
};

export type AssetsOrder = {
  report_id: string;
  created_at: string;
  completed_at: string;
  days_requested: number;
  large_deposit_threshold?: number;
  borrower: Borrower;
  links: AssetsLink[];
  summary: AssetsSummary;
};

export type CompanyAddress = {
  city?: string;
  state?: string;
  street?: string;
  zip?: string;
};

export type DataSource =
  | 'payroll'
  | 'docs'
  | 'insurance'
  /* @deprecated */
  | 'transactions'
  | 'financial_accounts'
  | 'scoring_attributes'
  | 'assets'
  | 'tax';

export enum SourceType {
  FLOIFY = 'floify',
  BESMARTEE = 'besmartee',
  LENDERLOGIX = 'lenderlogix',
  ENCOMPASS_CONSUMER_CONNECT = 'encompass_consumer_connect',
  BYTE = 'byte',
  CORE_LOGIC = 'core_logic',
  XACTUS = 'xactus',
  CONSTELLATION = 'constellation',
  BANNO = 'banno',
  MX = 'mx',
  Q2 = 'q2',
  CLUTCH = 'clutch',
  ACCIO = 'accio',
  ENCOMPASS = 'encompass',
  DARKMATTER = 'darkmatter',
  TAZWORKS = 'tazworks',
  INTERNAL = 'internal',
  SIMPLENEXUS = 'simplenexus',
  EXTERNAL_WEBPAGE = 'external_webpage',
  INDIVIDUAL = 'individual',
}

export type HiddenFromTimelineOrderEventType = 'refresh_failed';

export type OrderEventType =
  | 'order_created'
  | 'order_completed'
  | 'suborder_canceled'
  | 'suborder_expired'
  | 'suborder_completed'
  | 'suborder_skipped'
  | 'suborder_no_data'
  | 'email_first_sent'
  | 'email_first_delivery_error'
  | 'email_first_unsubscribed'
  | 'email_first_link_opened'
  | 'email_first_delivered'
  | 'sms_first_sent'
  | 'sms_first_unsubscribed'
  | 'sms_first_link_opened'
  | 'sms_first_delivered'
  | 'sms_first_delivery_error'
  | 'last_refresh'
  | 'login_attempt'
  | HiddenFromTimelineOrderEventType;

export type TimelineVisibleEventType = Exclude<OrderEventType, HiddenFromTimelineOrderEventType>;

export type OrderEvent = {
  title: OrderEventType;
  timestamp?: string;
  suborder_id: string | null;
  is_refresh_order_event: boolean;
};

export type StrictOrderProductType =
  | [VerificationType]
  | [Exclude<VerificationType, AdditionalProductTypes>, AdditionalProductTypes]
  | [AdditionalProductTypes, Exclude<VerificationType, AdditionalProductTypes>];

export type PaymentStatus = 'requires_payment_method' | 'requires_action' | 'processing' | 'succeeded' | 'canceled';

export type Payment = {
  status: PaymentStatus;
};

export type OrderGroup = {
  applicant: Applicant | null;
  /** @deprecated soon - use financial_accounts field from type instead */
  bank: SubOrder;
  canceled_at: string | null;
  cc_emails?: string[];
  company_logo?: string;
  company_name?: string;
  created_at: string;
  custom_field?: string;
  email?: string;
  env?: EnvType;
  events: OrderEvent[];
  expired_at: string;
  extra_data?: {
    refreshed_from_order?: string;
  };
  financial_accounts: SubOrder[] | null;
  /**
   * @deprecated soon
   */
  first_name: string;
  has_consent_form: boolean | null;
  has_paid_source: boolean;
  id: string;
  initial_order?: string;
  insurance: SubOrder;
  /**
   * @deprecated soon
   */
  last_name: string;
  last_payment: Payment | null;
  last_reminded_at: string;
  loan: {
    loan_number: string | null;
    originator_name: string | null;
  } | null;
  /**
   * @deprecated
   */
  loan_number: string | null;
  manager: null | {
    name: string;
    email: string;
  };
  order_number?: string;
  phone?: string;
  products: StrictOrderProductType;
  refresh_order: string | null;
  refresh_order_waits_for_approval?: boolean;
  refresh_suborders: SubOrder[];
  refreshed_at: string | null;
  share_url: string | null;
  short_share_url: string | null;
  source: SourceType;
  suborders: SubOrder[];
  template?: {
    id: string;
    name: string;
  };
  user_id: string;
  voa_report_id: string | null;
  voie_report_id: string | null;
};

export type OrderGroupPagination = ListResult<OrderGroup>;

export type OrderGroupParams = PagingFilters &
  Partial<{
    status: OrderFilterStatus;
    created_at__lte: string;
    created_at__gte: string;
    expired_at__lte: string;
    expired_at__gte: string;
    canceled_at__gte: string;
    canceled_at__lte: string;
    phone: string;
    email: string;
    source: string;
    products: VerificationType;
    order_number: string;
    search: string;
    ordering: string;
    env?: EnvType[];
    manager__email__in?: string;
    refresh?: 'only' | 'exclude';
  }>;

export type CreateOrderGroup = Partial<OrderGroup> & {
  products: StrictOrderProductType;
  source: string;
  first_name: string;
  last_name: string;
  ssn?: string;
  custom_field?: string;
  template_id?: string;
  cc_emails?: string[];
};

export type CreateOrderGroupParams = {
  order_group: CreateOrderGroup;
  employments?: CreateOrderGroupEmployment[];
  insurance?: {
    provider_id: string;
  };
  reports?: {
    voa?: {
      days_requested?: number;
      request_extended_history?: boolean;
      large_deposit_threshold?: {
        fixed_amount?: number;
        sales_price?: number;
        qualifying_monthly_income?: number;
        loan_type?: 'OTHER' | 'CONVENTIONAL' | 'FHA';
      };
    };
  };
  env: EnvType;
};

export type CreateOrderGroupEmployment = {
  company_address?: string;
  company_domain?: string;
  company_google_place_id?: string;
  company_logo?: string;
  company_name?: string;
  end_date?: string;
  start_date?: string;
  account?: BankAccount;
};

export const REFRESHABLE_PRODUCT_TYPES = ['income', 'employment', 'insurance', 'assets', 'transactions'] as const;

export type RefreshableProductType = (typeof REFRESHABLE_PRODUCT_TYPES)[number];

export type RefreshOrderEmployer = {
  id: string;
  // TODO: not used by BE yet, need to sync with BE
  product_type: RefreshableProductType;
};

export type RefreshOrderRequest = {
  products?: RefreshableProductType[];
  employers?: RefreshOrderEmployer[];
  include_recent_paystub?: boolean;
  env: EnvType;
};

export type RefreshOrderResponse = OrderGroup;

export type EditOrderRequest = {
  env: EnvType;
  first_name?: string;
  last_name?: string;
  refresh_approved?: boolean;
  ssn?: string;
};

export type OrderFileResponse = {
  id: string;
  order_group_id: string;
  file: string;
  created_at: string;
  updated_at: string;
  status: 'pending' | 'success' | 'error';
};

export type Address = {
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  zip?: string;
};
export type Company = {
  name?: string;
  address?: Address;
  phone?: string;
};

export type BankAccount = {
  account_name?: string | null;
  account_number?: string | null;
  account_type?: string | null;
  bank_name?: string | null;
  bank_address?: string | null;
  deposit_type?: DepositType | null;
  deposit_value?: string | number | null;
  routing_number?: string | null;
  days_requested?: number;
};

export type DepositType = 'entire' | 'percent' | 'amount';

export type AnnualSummary = {
  id: string | null;
  year: number | null;
  regular: string | null;
  bonus: string | null;
  commission: string | null;
  overtime: string | null;
  other_pay: string | null;
  net_pay: string | null;
  gross_pay: string | null;
};

export type W2 = {
  federal_tax: string | null;
  file: string | null;
  gross_pay: string | null;
  md5sum: string | null;
  medicare_tax: string | null;
  medicare_wages: string | null;
  social_security_tax: string | null;
  social_security_wages: string | null;
  wages: string | null;
  year: number | null;
};

export type NotEmptyW2 = W2 & { file: string; year: number };

export type Employment = {
  annual_salary: string;
  id: string;
  job_title: string;
  job_type: string;
  start_date: string | null;
  end_date: string | null;
  employed_in_role: string | null;
  external_last_updated: string;
  hourly_salary: string;
  original_hire_date?: string;
  company?: Company;
  profile: Identity;
  statements: Statement[];
  comment: string;
  do_not_contact: string;
  dates_from_statements?: boolean;
  is_active?: boolean;
  manager_name?: string;

  income?: string | null;
  income_unit?: string | null;
  pay_frequency?: string | null;
  annual_income_summary?: AnnualSummary[];
  bank_accounts?: BankAccount[];
  w2s?: W2[];
};

type Deduction = { amount: string; category: string; name: string };

export type Statement = {
  id: string | null;
  pay_date: string | null;

  net_pay: string | null;
  net_pay_ytd: string | null;

  deductions: Deduction[] | null;
  deductions_ytd: Deduction[] | null;
  gross_pay: string | null;
  gross_pay_ytd: string | null;

  hours: string | null;
  basis_of_pay: string | null;

  period_start: string | null;
  period_end: string | null;

  regular: string | null;
  regular_ytd: string | null;

  bonus: string | null;
  bonus_ytd: string | null;

  commission: string | null;
  commission_ytd: string | null;

  overtime: string | null;
  overtime_ytd: string | null;

  other_pay: string | null;
  other_pay_ytd: string | null;

  md5sum: string | null;
  file: string | null;
};

export type InviteStatus = 'pending' | 'expired' | 'completed' | 'error';

export type Invite = {
  id: string;
  status: InviteStatus;
  created_at: string;
  expired_at: string;
};

export type RoleId = 'administrator' | 'developer' | 'orders_manager' | 'orders_tasks_manager' | 'owner';

export type Role = {
  id: RoleId;
  name: string;
  description: string;
};

export type Member = {
  user_id: string;
  first_name: string;
  last_name: string;
  email: string;
  is_owner: boolean;
  created_at: string;
  is_active: boolean; // use it later to temporary disable users
  is_approved: boolean | null;
  invite?: Invite;
  role: Role;
  access_to_templates: string[];
};

export type UpdateMemberResult = {
  first_name: string;
  last_name: string;
  role: RoleId;
  access_to_templates: string[];
};

export type CreateMemberResult = {
  user_id: string;
  email: string;
  first_name: string;
  last_name: string;
  role: RoleId;
  access_to_templates: string[];
};

export type DayReportItem = {
  date: string;
  value: number | null;
};

type ProviderReportItem = {
  provider_id: string;
  provider_name: string;
  provider_logo: string;
  data: DayReportItem[];
};

export type ProviderStatusesResponse = {
  summary: DayReportItem[];
  providers: ProviderReportItem[];
};

export type ProviderBase = {
  id: string;
  name: string;
  logo_url?: string;
};

export type Provider = ProviderBase & {
  data_source: string;
  is_disabled: boolean;
};

export type ProvidersListResponse = ListResult<Provider>;

export type ProvidersListRequest = Partial<PagingFilters> & {
  data_source?: DataSource;
  product_type: VerificationType;
  query?: string;
  ordering?: 'id' | '-id' | 'name' | '-name' | 'rank' | '-rank';
};

export type CategorizedCompanyMappingBase = {
  name: string;
  logo_url: string;
  success_rate: SuggestionSuccessRate;
  confidence_level: string;
  sso: {
    name: string;
  };
};

export type CategorizedCompanyMappingWidget = CategorizedCompanyMappingBase & {
  domain: string;
  company_mapping_id: string;
};

export type CategorizedCompaniesTypeBase = {
  id: CompaniesCategoryId;
  name: string;
};

export type CategorizedCompaniesType = CategorizedCompaniesTypeBase & {
  mappings: CategorizedCompanyMappingBase[];
};

export type CategorizedCompaniesWidget = CategorizedCompaniesTypeBase & {
  mappings: CategorizedCompanyMappingWidget[];
  show_search_item?: boolean | null;
  is_visible?: boolean;
};

export type InsurancePolicy = {
  carrier_name: string;
  canceled_date: string | null;
  carrier_policy_number: string;
  claims: InsuranceClaim[];
  commercial_named_insureds: InsuranceCommercialNamedInsured[];
  created_at: string;
  deductible_cents: null;
  description: string;
  documents: InsuranceDocument[];
  dwellings: InsuranceDwelling[];
  effective_date: string;
  expiry_date: string;
  id: string;
  loss_events: InsuranceLossEvent[];
  name: string;
  policy_type: string;
  renewal_date: string | null;
  status: string;
  total_premium_cents: number;
  updated_at: string;
  vehicles: InsuranceVehicle[];
};

export type InsuranceCommercialNamedInsured = {
  id: string;
  name: string;
  form_of_business: string;
  gl_code: string;
  sic_code: string;
  naics_code: string;
  fein: string;
  ssn: string;
  is_primary: boolean;
  address: Address;
};

export type InsuranceLossEvent = {
  id: string;
  date_of_occurrence: string;
  date_of_claim: string;
  type: string;
  amount_paid_cents: number;
  amount_reserved_cents: number;
  is_subrogation: boolean;
  is_claim_open: boolean;
};

export type InsuranceClaim = {
  id: string;
  dwelling_id: string;
  vehicle_id: string;
  driver_id: string;
  carrier_claim_identifier: string;
  date_occurred: string;
  type: string;
  status: string;
  date_closed: string;
  payout_cents: number;
  representative_name: string;
  representative_phone: string;
  representative_email: string;
  address: Address;
};

export type InsuranceDocument = {
  id: string;
  title: string;
  document_type: string;
  date_added: string;
  file: string;
};

export type InsuranceCoverage = {
  name: string;
  premium_cents: number;
  per_person_limit_cents: number;
  per_incident_limit_cents: number;
  deductible_cents: number;
  is_declined: boolean;
  id: string;
  per_day_limit_cents: number;
  per_mile_premium_tenth_of_cents: number;
};

export type InsuranceMortgagee = {
  id: string;
  type: string;
  loan_number: string;
  name: string;
  address: Address;
};

export type InsuranceVehicle = {
  id: string;
  year: number;
  make: string;
  model: string;
  series: string;
  series2: string;
  type: string;
  annual_mileage: number | null;
  vin: string;
  purchase_date: string | null;
  is_removed: boolean;
  lien_holder: string;
  garaging_address: Address;
  lien_holder_address: Address;
  coverages: InsuranceCoverage[];
  drivers: InsuranceDriver[];
};

export type InsuranceDwelling = {
  id: string;
  address: Address;
  coverages: InsuranceCoverage[];
  mortgagees?: InsuranceMortgagee[];
};

export type InsuranceDriver = {
  first_name: string;
  id: string;
  middle_name: string;
  last_name: string;
  drivers_license: string;
  drivers_license_state: string;
  date_of_birth_str: string;
  gender: string;
  marital_status: string;
  relationship_to_insured: string;
  is_excluded: true;
};

export type InsuranceDrivingRecords = {
  id: string;
  driver_id: string;
  incident_date: string;
  incident_type: string;
  violation_type: string;
  is_at_fault: boolean;
};

export type Insurance = {
  policies: InsurancePolicy[];
  drivers: InsuranceDriver[];
  driving_records: InsuranceDrivingRecords[];
};

export type AccountOwner = {
  address: Address;
  email: string;
  full_name: string;
  id: string;
  phone: string | null;
};

export type Account = {
  balances: {
    available_balance: string | null;
    balance: string;
    credit_limit: string | null;
    currency_code: string | null;
  };
  direct_deposit_from_employer: boolean;
  same_owner_as_requested: boolean;
  summary: AssetsSummary;
  nsf: number;
  created_at: string | null;
  days_available: number;
  id: string;
  nickname: string | null;
  owners: AccountOwner[];
  subtype: string | null;
  transactions: Transaction[];
  routing_number: string;
  mask: string | null;
  type: string | null;
  updated_at: string;
};

export type TransactionsAccount = {
  account_number: string;
  account_type: string;
  balances: {
    currency_code: string | null;
    balance: string;
  };
  external_id: string;
  id: string;
  is_closed: boolean;
  nickname: string | null;
  owners: AccountOwner[] | null;
  provider: string;
  routing_number: string;
  mask: string;
  type: string;
};
export type TransactionStatus = 'POSTED' | 'PENDING';
export type TransactionType = 'DEBIT' | 'CREDIT';
export type Transaction = {
  account_id: string;
  amount: string;
  categories: string[];
  check_number: string | null;
  currency_code: string | null;
  description: string;
  ending_daily_balance: string | null;
  external_id: string;
  id: string;
  merchant_category_code: string | null;
  posted_at: string;
  status: TransactionStatus;
  transacted_at: string;
  type: TransactionType;
  is_subscription: boolean;
  is_direct_deposit: boolean;
};

export type TransactionsResponse = {
  next: string | null;
  previous: string | null;
  count: number;
  results?: Transaction[];
  transactions: Transaction[];
  accounts: TransactionsAccount[];
};

export type Identity = {
  created_at: string;
  date_of_birth: string;
  email: string;
  first_name: string;
  full_name: string;
  home_address: Address;
  id: string;
  last_name: string;
  middle_initials: string;
  ssn: string | null;
  updated_at: string;
};

export type VerificationResult = {
  large_deposit_threshold?: number;
  status?: 'full_parse' | 'done';
  data_source?: DataSource;
  payroll_report_id?: string;
  deposit_details?: BankAccount;
  insurance?: Insurance;
  identity?: Identity;
  pdf_report?: string;
  transactions?: TransactionsResponse;
  tax_documents?: W2[];
  // it's needed only for transactions, because of broken pagination TODO - transactions pagination
  link_id?: string;
};

export type VerificationRequestBody = {
  access_token: string;
  start_date?: string;
  end_date?: string;
};

export type SupportTicketUser = {
  id: number;
  name: string;
  organization_id: number;
  is_agent: boolean | null;
  is_truv: boolean | null;
};

type SupportTicketOrganization = {
  id: number;
  name: string;
};

type SupportTicketBaseInfo = {
  organizations: SupportTicketOrganization[];
  users: SupportTicketUser[];
};

export type SupportTicketAttachment = {
  id: number;
  file_name: string;
  content_url: string;
  content_type: string;
  size: number;
  deleted: boolean;
};

export type SupportTicketComment = {
  id: number;
  author_id: number;
  body: string;
  html_body: string;
  attachments: SupportTicketAttachment[];
  created_at: string;
};

export type SupportTicketsRequest = PagingFilters & { by_current_user?: string; search?: string };
export type UploadAttachmentResponse = { token: string; fileName: string; content_type: string };

export type CreateSupportTicketRequest = {
  request_type: SupportTicketCreateType;
  subject: string;
  details: string;
  attachments?: string[];
  category?: CategoryId;
  subcategory?: SubcategoryId;
  order_id?: string;
  task_id?: string;
};

export type ReplyToSupportTicketRequest = {
  details: string;
  attachments?: string[];
};

export type SupportTicketCategory = 'problem' | 'incident' | 'question' | 'task';
export type SupportTicketCreateType = 'problem' | 'question';
export type SupportTicketStatus = 'opened' | 'resolved';

type TicketPriority = 'urgent' | 'high' | 'normal' | 'low';

export type SupportTicketsResult = {
  id: string;
  subject: string;
  status: SupportTicketStatus;
  priority: TicketPriority;
  created_at: string;
  updated_at: string;
  requester_id: number | null;
  assignee_id: number | null;
  submitter_id: number | null;
  organization_id: number | null;
  /** @deprecated use users field from type SupportTicketsResponse instead */
  requester: {
    name: string;
    email: string;
  };
};

export type SupportTicketsStatuses = {
  opened: number;
  resolved: number;
};

export type SupportTicketsResponse = ListResult<SupportTicketsResult> &
  SupportTicketBaseInfo & {
    facets: {
      statuses: SupportTicketsStatuses;
    };
  };

export type SupportTicketInfoResponse = SupportTicketBaseInfo & {
  ticket: SupportTicketsResult;
};

export type SupportTicketCommentsResponse = SupportTicketBaseInfo &
  BaseListResult & {
    comments: SupportTicketComment[];
  };

export type ImpersonateResponse = {
  id: string;
  target: string;
};

// combinations are alphabet sorted! income always comes first, then insurance, then transactions
export type TemplatesProductCombinations =
  | VerificationType
  | 'income,insurance'
  | 'assets,income'
  | 'assets,insurance'
  | 'assets,income,insurance'
  | '';

export type TemplateBridgeTexts = {
  search_header?: string;
  success_cta?: string;
  success_title?: string;
  success_subtitle?: string;
};

export type CompaniesCategoryId =
  | 'popular'
  | 'popular_sandbox'
  | 'employers'
  | 'providers'
  | 'gig'
  | 'unemployment'
  | 'other'
  | 'unemployment_mapped'
  | 'tax'
  | 'peo';

export type TemplateBridgeSearch = {
  popular_listing_id?: string;
  categories?: CompaniesCategoryId[];
};

export type TemplateBridge = {
  texts?: TemplateBridgeTexts;
  search?: TemplateBridgeSearch;
};

export type TemplateBranding = {
  company_name?: string | null;
  logo?: string | File;
  accent_color?: string;
  background_color?: string;
  hide_confetti?: boolean;
  custom_end_user_agreement?: {
    header: string | null;
    url: string | null;
  };
  custom_product_names?: {
    pll: string;
  };
};

export type TemplateEmailSettings = {
  subject?: string;
  header?: string;
  button?: string;
  caption?: string;
};

export type TemplateFirstEmailSettings = TemplateEmailSettings & {
  apply_for_reminder?: boolean;
};

export type TemplateSMSSettings = {
  text?: string;
};

export type TemplateFirstSMSSettings = TemplateSMSSettings & {
  apply_for_reminder: boolean;
};

export type TemplateLandingSettings = {
  header?: string;
  body?: string;
};

export type BankAccountType = 'checking' | 'savings';

export type TemplateOrder = {
  custom_field_title?: string | null;
  first_email?: TemplateFirstEmailSettings;
  first_sms?: TemplateFirstSMSSettings;
  landing?: TemplateLandingSettings & {
    hide_faq: boolean;
    success_screen?: TemplateLandingSettings;
    expired_screen?: TemplateLandingSettings;
  };
  link_expiration?: RemindTypeNew;
  reminder_email?: TemplateEmailSettings;
  reminder_sms?: TemplateSMSSettings;
  support_email?: string;
  bank_info?: {
    bank_name?: string;
    bank_address?: string;
    account_number?: string;
    routing_number?: string;
    account_type?: BankAccountType;
  };
};

export type DocUploadConfig = {
  title: string;
  submit_title: string;
  description: string;
  min_count: number;
  max_count: number;
  max_bytes: number;
  allowed_mimetypes: string[];
  is_required: boolean;
  is_enabled: boolean;
  allowed_products: VerificationType[];
  button: string;
};

export type DocumentType = 'f1040' | 'f1099' | 'paystub' | 'w2';
export type TemplateDocUpload = Record<DocumentType, DocUploadConfig> & { is_enabled: boolean };

export type TemplateReportSection = {
  deposits?: boolean;
  historical_payment_summary?: boolean;
};

export type TemplateSettings = {
  name: string;
  products: VerificationType[];
  reports: {
    hidden_sections: TemplateReportSection;
    days_requested: number | null;
    large_deposit_threshold: number | null;
  };
  data: {
    paystubs_ytd_count: number;
    paystubs_count: number;
    w2_count: number;
    is_enabled: boolean;
  };
  default_for_products?: boolean;
  bridge?: TemplateBridge;
  branding?: TemplateBranding;
  orders?: TemplateOrder;
  document_upload: TemplateDocUpload;
  manager?: {
    name: string;
    email: string;
  };
};

export type GetTemplateResponse = TemplateSettings & {
  id: string;
  created_at: string;
};

export type GetTemplatesResponse = ListResult<GetTemplateResponse>;

export type UpdateTemplateProps = {
  id: string;
  payload: Partial<TemplateSettings>;
};

export type GetTemplatesRequest = PagingFilters & {
  search?: string;
  products?: TemplatesProductCombinations;
};

export type GetPopularCompaniesListIdResponse = {
  id: string;
};

export type CreateBridgeUserParams = {
  external_user_id: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  ssn?: string;
};

export type BridgeUserResponse = CreateBridgeUserParams & {
  id: string;
  created_at: string;
  updated_at: string;
  links: BridgeUserLink[] | null;
  /**
   * GSE accepted income and employment verification report ID
   */
  voie_report_id: string | null;
  /**
   * Verification of Assets report ID
   */
  voa_report_id: string | null;
  /**
   * AIM check report ID
   */
  aim_check_report_id: string | null;
};

export type BridgeUserLink = {
  id: string;
  created_at: string;
  updated_at: string;
  tracking_info?: string;
  status: ValueOf<typeof TaskStatusMap>;
  user_external_id?: string;
  provider_id?: string;
  provider?: ProviderBase;
  company_mapping?: ProviderBase;
  link_hash?: string;
  allowed_products?: VerificationType[];
  initial_product_type: VerificationType;
  data_source?: DataSource;
  is_suspicious?: boolean;
};

export type GetBridgeUsersParams = Partial<PagingFilters> &
  BaseFilters & {
    query?: string;
    product_type?: string;
    provider?: string;
    list_links?: boolean;
    link_exists?: boolean;
  };

export type GetBridgeUsersResponse = ListResult<BridgeUserResponse>;

export type UsageReportsParams = {
  year: string;
} & Partial<PagingFilters>;

export type UsageReportsResponse = ListResult<{
  created_at: string;
  id: string;
  invoice_id: string;
  period_end: string;
  period_start: string;
  report: string | null;
}>;

export type GetTwilioSettings = {
  twilio_account_sid: string;
  twilio_phone_number: string;
  twilio_messaging_service_sid?: string;
};

export type UpdateTwilioSettings = GetTwilioSettings & {
  twilio_auth_token?: string; // write-only field
};

export type UploadedDocumentInfo = {
  id: string;
  type: string;
  subtype: string;
  filename: string;
  file: string;
  created_at: string;
  updated_at: string;
};

export type ScoringAttributesFileResponse = {
  url: string;
};

export type RequestCoverageParams = {
  product_type: VerificationType;
  company_name: string;
  company_website?: string;
  provider_name?: string;
  comment?: string;
};

export type SubcompaniesResponse = {
  id: string;
  name: string;
  legal_name: string;
  logo: string;
  owner_email: string;
}[];

export type AnalyticsSourceFilters = {
  period_start?: string;
  period_end?: string;
  product_type?: string;
  template?: string;
  data_source?: string;
  companies?: string;
  goal?: number;
};

export type AnalyticsEmbeddedTotalsResponse = {
  users: number;
  users_trend: number | null;
  completed: number;
  completed_trend: number | null;
  cr: number | null;
  cr_trend: number | null;
}[];

export type AnalyticsOrdersTotalsResponse = {
  orders: number;
  completed: number;
  canceled_orders: number;
  email_open_rate: number;
  email_cr: number | null;
  sms_cr: number | null;
  cr: number | null;
  canceled_orders_trend: number | null;
  orders_trend: number | null;
  completed_trend: number | null;
  cr_trend: number | null;
  time_to_complete: number | null;
}[];

export type AnalyticsEmbeddedTrendResponse = {
  date: string;
  preceding_period_cr: number | null;
  preceding_period_users: number;
  selected_period_completed: number;
  selected_period_cr: number | null;
  selected_period_users: number;
}[];

export type AnalyticsOrdersTrendResponse = {
  date: string;
  selected_period_orders: number;
  selected_period_completed: number;
  selected_period_cr: number | null;
  preceding_period_orders: number;
  preceding_period_completed: number;
  preceding_period_cr: number | null;
}[];

export type AnalyticsEmbeddedFunnelResponse = {
  status_id: number;
  users: number;
  drop_off: number;
  cr: number | null;
  cr_from_previous_step: number | null;
}[];

export type AnalyticsOrdersFunnelResponse = {
  status_id: number;
  orders: number;
  drop_off: number;
  cr: number | null;
  cr_from_previous_step: number | null;
}[];

export type AnalyticsOrdersStatusDistributionResponse = {
  status: 'expired' | 'completed' | 'skipped' | 'canceled' | 'no_data' | 'error' | 'pending' | 'sent';
  orders: number;
}[];

export type AnalyticsErrorsDistributionResponse = {
  error:
    | 'login_error'
    | 'no_data'
    | 'mfa_error'
    | 'unavailable'
    | 'config_error'
    | 'error'
    | 'account_locked'
    | 'unable_to_reset';
  users: number;
}[];

export type AnalyticsTATMedianResponse = {
  median_tat: number | null;
}[];

export type AnalyticsTATTotalsResponse = {
  created: number;
  completed: number;
}[];

export type AnalyticsTATHistogramResponse = {
  hours_cat: string;
  total_orders: number;
}[];

export type AnalyticsBenchmarksResponse = {
  industry: string;
  users: number;
  cr: number | null;
  benchmark_cr: number | null;
}[];

export type AnalyticsTeamPerformanceTableResponse = {
  completed_orders: number;
  completion_rate: number;
  created_orders: number;
  manager_email: string | null;
  manager_name: string | null;
}[];

export type AnalyticsTeamPerformanceTotalsResponse = {
  active: number;
  inactive: number;
  pending: number;
  total: number;
}[];

export type CreateScoringAttributesReportResponse = {
  report_id: string;
};

export type ScoringAttributesReportResponse = {
  status: 'processing' | 'success';
  [key: string]: unknown;
};

export type PlatformKeysBannoParams = {
  client_id: string | null;
  client_secret: string | null;
  environment: string | null;
};

export type PlatformKeysBannoResponse = PlatformKeysBannoParams & {
  plugin_url_static: string | null;
  plugin_url_dynamic: string | null;
  id: string;
};

export type PlatformKeysBannoError = {
  [k in keyof PlatformKeysBannoParams]?: string[];
};

export type PlatformKeysBlackKnightParams = {
  party_id: string;
};

export type PlatformKeysBlackKnightResponse = PlatformKeysBlackKnightParams & {
  id: string;
};

export type PlatformKeysBlackKnightError = {
  [k in keyof PlatformKeysBlackKnightParams]?: string[];
};

export type PlatformKeysSimpleNexusParams = {
  api_key: string | null;
};

export type PlatformKeysSimpleNexusResponse = PlatformKeysSimpleNexusParams & {
  id: string;
  core_user_key_env: EnvType;
};

export type PlatformKeysSimpleNexusError = {
  [k in keyof PlatformKeysSimpleNexusParams]?: string[];
};

export type CompanySearchPublicResponse = TruvSuggestionType[];

export type BatchOrdersRequest = {
  products: VerificationType[];
  template_id?: string;
  file: File;
  env_type: EnvType;
};

export type BatchOrdersResponse = {
  created_at: string;
  data: BatchOrderData[];
  errors: ({
    [key in keyof BatchOrderData]?: string[];
  } & {
    employers?: {
      account?: {
        [key in keyof BankAccount]?: string[];
      };
    }[];
  } & {
    lineno: number;
  })[];
  id: string;
  order_groups:
    | {
        id: string;
        first_name: string;
        last_name: string;
        share_url: string;
        email: string | null;
        phone_number: string | null;
      }[]
    | null;
  status: 'pending' | 'prepared' | 'completed' | 'failed';
  updated_at: string;
};

export type BatchOrderData = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  ssn: string | null;
  employer_names: string[];
  tracking_number: string | null;
  loan_number: string | null;
  loan_originator_name: string | null;
  template_id?: string;
  custom_field: string | null;
  bank_name: string | null;
  bank_address: string | null;
  routing_number: string | null;
  account_number: string | null;
  account_type: string | null;
  deposit_type: string | null;
  deposit_value: string | null;
};

export type BatchOrdersError =
  | string[]
  | {
      [key in keyof BatchOrdersRequest]?: string[];
    };

export type PerformanceReportsParams = Partial<PagingFilters> & {
  year?: string;
  use_case?: string;
};

export type PerformanceReportsResponse = ListResult<{
  id: string;
  user_id: string;
  products: StrictOrderProductType;
  use_case: string;
  report_date: string;
  report: string;
  created_at: string;
  updated_at: string;
}> & {
  use_cases: UseCase[];
};

export enum UseCase {
  BACKGROUND_SCREENING = 'background_screening',
  MORTGAGE_LENDING = 'mortgage_lending',
  HOME_EQUITY_LENDING = 'home_equity_lending',
  CONSUMER_LENDING = 'consumer_lending',
  AUTO_LENDING = 'auto_lending',
  TENANT_SCREENING = 'tenant_screening',
  BANKING = 'banking',
  GOVERNMENT = 'government',
}

export type BridgeToken = {
  bridge_token: string;
  tracking_info?: string;
  client_name?: string;
  product_type?: VerificationType;
  allowed_products?: VerificationType[];
  company_mapping_id?: string;
  access_token?: string;
  user_id?: string;
};

export type BridgeTokenSettings = {
  product_type: VerificationType;
  tracking_info?: string;
  allowed_products?: string;
  company_mapping_id?: string;
  access_token?: string;
  provider_id?: string;
  template_id?: string;
  account?: BankAccount;
  data_sources?: DataSource[];
  data_source_flow?: DataSourceFlow;
};

export type BridgeTokenParams = {
  type: VerificationType;
  mappingId?: string;
  providerId?: string;
  template?: string;
  account?: BankAccount;
  dataSources?: string;
  dataSourceFlow?: DataSourceFlow;
};

export type DataSourcesParams = {
  product_type?: VerificationType;
  template_id?: string;
  use_case?: UseCase;
};

export type DataSourcesResponse = Record<VerificationType, DataSource[]>;

export type UserProperties = {
  isDevRequestSubmitted?: boolean;
  isProductionRequestSubmitted?: boolean;
  isCustomizationRequestSent?: boolean;
  isSandboxMode?: boolean;
  lastOrderProductType?: VerificationType[];
  lastEmulatorProductType?: ProductType;
  targetVisited?: boolean;
};

export type WebhookItem = {
  id: string;
  name: string;
  webhook_url: string;
  events: string[];
  env_type: EnvType;
  created_at: string;
};

export type CreateWebhookParams = Omit<WebhookItem, 'id' | 'created_at'>;

export type UpdateWebhookParams = {
  id: string;
  payload: Partial<CreateWebhookParams>;
};

export type WebhookError = {
  // @deprecated current API returns error in `webhook_url` field,
  detail?: string;
  webhook_url?: string[];
};

export type UpdateCompanyRequest = Partial<Omit<CompanyResponse, 'client_id' | 'has_prod_usage'>>;
