import { useState } from 'react';

import { useHistory } from 'react-router-dom';

import type { ImpersonateResponse } from 'api/types';
import { useFiltersContext } from 'shared/lib/filters/useFiltersContext';
import { impersonationEmailKey, impersonationIdKey } from 'shared/lib/impersonation/common';
import { getImpersonationProps } from 'shared/lib/impersonation/getImpersonationProps';
import { setImpersonationParams } from 'shared/lib/impersonation/setImpersonationParams';

export const useImpersonation = () => {
  const { resetFilters } = useFiltersContext();
  const [email, setEmail] = useState(getImpersonationProps(impersonationEmailKey));
  const [id, setId] = useState(getImpersonationProps(impersonationIdKey));
  const history = useHistory();

  return {
    impersonate: ({ target, id }: ImpersonateResponse) => {
      resetFilters();
      setImpersonationParams(target, id);
      setEmail(email);
      setId(id);
      history.replace({ search: '' });
      history.go(0);
    },
    stopImpersonation: (keepSearchParams = false) => {
      resetFilters();
      setImpersonationParams('', '');
      setEmail('');
      setId('');

      if (!keepSearchParams) {
        history.replace({ search: '' });
      }

      history.go(0);
    },
    [impersonationEmailKey]: email,
    [impersonationIdKey]: id,
  };
};
